<template>
    <div style="width: 100%;overflow-x: hidden;">
        <top></top>
        <div class="topic">
            <div class="card-wrap">
                <div class="title t-c">全真考试题库·紧扣考试大纲</div>
                <div class="f16 c66 t-c">全面覆盖了考试大纲的知识点和重点，确保考生能够接触到与真实考试高度一致的题型和难度</div>
                <div class="d-f topic_">
                    <div class="c33 f20 tab t-c">
                        <div @mouseover="hovetab(0)" @mouseleave="handtab()" :class="[tab == 0?'tabs cff':'']">题库系统</div>
                        <div @mouseover="hovetab(1)" @mouseleave="handtab()" :class="[tab == 1?'tabs cff':'']">视频教学</div>
                        <div @mouseover="hovetab(2)" @mouseleave="handtab()" :class="[tab == 2?'tabs cff':'']">模拟考场</div>
                        <div @mouseover="hovetab(3)" @mouseleave="handtab()" :class="[tab == 3?'tabs cff':'']">错题分析</div>
                    </div>
                    <div class="content p-r" v-show="tab == 0">
                        <div v-show="screenWidth > 1200" class="c00 f28 f-w">题库系统</div>
                        <div class="c3d f16 contents">信息考试系统的题库系统，涵盖多模块试题，含选择题及Windows、Word、Excel、PPT、IE等操作题，满足多样考试需求，实现高效组卷与管理。</div>
                        <div class="f16 t-c c49 con_">
                            <div>单项选择</div>
                            <div>Windows操作题</div> 
                            <div>Excel操作题</div>
                            <div>IE浏览器</div>
                            <div>Word操作题</div>
                            <div>……</div>
                        </div>
                        <img class="p-a" src="../assets/home_1.png" alt="">
                    </div>
                    <div class="content p-r" v-show="tab == 1">
                        <div v-show="screenWidth > 1200" class="c00 f28 f-w">视频教学</div>
                        <div class="c3d f16 contents">视频教学模块直观地演示操作技巧，高效备考，轻松掌握考点。学生可以在操作过程中获得详细步骤指导和视频演示。帮助学生查漏补缺，解决学习中的难点和痛点。</div>
                        <div class="f16 t-c c49 con_">
                            <div>步骤详析</div>
                            <div>自学指引</div>
                            <div>视频辅导</div>
                            <div>难点攻克</div>
                            <div>查漏补缺</div>
                            <div>……</div>
                        </div>
                        <img class="p-a" src="../assets/home_2.png" alt="">
                    </div>
                    <div class="content p-r" v-show="tab == 2">
                        <div v-show="screenWidth > 1200" class="c00 f28 f-w">模拟考场</div>
                        <div class="c3d f16 contents">模拟考场，精准还原考试环境，助力考生实战演练，高效备考，它不仅能够帮助考生提前适应真实的考试环境，还能够全面考核考生的能力、提供即时的反馈与评估，学生可居家自评分数。</div>
                        <div class="f16 t-c c49 con_">
                            <div>环境预演</div>
                            <div>能力全检</div>
                            <div>即时反馈</div>
                            <div>个性辅导</div>
                            <div>精准评估</div>
                            <div>……</div>
                        </div>
                        <img class="p-a" src="../assets/home_3.png" alt="">
                    </div>
                    <div class="content p-r" v-show="tab == 3">
                        <div v-show="screenWidth > 1200" class="c00 f28 f-w">错题分析</div>
                        <div class="c3d f16 contents">系统智能记录做题情况，对已作答、部分作答和未作答的题目分别标记，帮助学生清晰了解自己的练习情况。帮助考生更有效地识别和纠正自己的错误，从而提高学习效率和学习成绩。</div>
                        <div class="f16 t-c c49 con_">
                            <div>智能记录</div>
                            <div>分类清晰</div>
                            <div>错题识别</div>
                            <div>纠正高效</div>
                            <div>成绩提升</div>
                            <div>……</div>
                        </div>
                        <img class="p-a" src="../assets/home_4.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="environment p-r">
            <div class="card-wrap p-r">
                <div class="title t-c">模拟真实考试环境</div>
                <div class="cff f26 t-c" v-show="activeIndex == 0">全真题库</div>
                <div class="cff f26 t-c" v-show="activeIndex == 2">个性化选择</div>
                <div class="cff f26 t-c" v-show="activeIndex == 1">激活帮助</div>
                <div style="height: 55px;" class="f16 c7b t-c" v-show="activeIndex == 0">信息考试全真题库的内容广泛且多样，涵盖多个模块多种题型，让您的测评更系统、更全面</div>
                <div style="height: 55px;" class="f16 c7b t-c" v-show="activeIndex == 2">适用于初中学业水平考试、会考、高考等信息技术考试。根据考生的必修科目和选修科目，可自由勾选科目进行组卷，定制您的专属测评系统。</div>
                <div style="height: 55px;" class="f16 c7b t-c" v-show="activeIndex == 1">旨在为用户提供便捷、高效的软件激活服务，确保用户能够顺利使用软件的全部功能。更好的服务</div>
                <div class="swiper-button-prev swiper-button" @click.stop="prevEl(item, index)">
                <img class="p-a left" src="../assets/left.png"/>
            </div>
            <div class="swiper-button-next swiper-button" @click.stop="nextEl">
                <img class="p-a right" src="../assets/right.png"/>
            </div>
            </div>
            <swiper @slideChange="SlideChange" :autoplay="{ delay: 2500 }" :modules="modules" :slides-per-view="1"
                :space-between="96"
                :centered-slides="true"
                :navigation="navigation"
                :pagination="{ clickable: true }">
                <swiper-slide>
                    <img  src="../assets/01.jpg"/>
                </swiper-slide>
                <swiper-slide>
                    <img src="../assets/02.jpg"/>
                </swiper-slide>
                <swiper-slide>                
                    <img src="../assets/03.jpg"/>
                </swiper-slide>
                <!-- 指示点 -->
                <div class="swiper-pagination"></div>
            </swiper>
            <img class="p-a diannao" src="../assets/diannao.png"/>
        </div>
        <div class="function">
            <div class="card-wrap">
                <div class="title t-c">全真模拟·练习题库</div>
                <div class="f16 c66 t-c tips">信考百分是一个高效、全面、便捷的考试信息测评平台，提供丰富资源与模拟环境，助力学生精通信息技术，提升应试实力。</div>
                <div class="funs">
                    <div class="fun t-c">
                        <img src="../assets/fun1.png" alt="">
                        <div class="c00 f18 f-w">题型丰富多样</div>
                        <div class="c66 f14 tips_">涵盖多模块试题，含选择题及Windows、Word、Excel、PPT、IE等操作题。</div>
                    </div>
                    <div class="fun t-c">
                        <img src="../assets/fun2.png" alt="">
                        <div class="c00 f18 f-w">智能测评反馈</div>
                        <div class="c66 f14 tips_">练习过程中随时提交，即时分析答案，智能评估，给予准确反馈。</div>
                    </div>
                    <div class="fun t-c">
                        <img src="../assets/fun3.png" alt="">
                        <div class="c00 f18 f-w">个性化学习</div>
                        <div class="c66 f14 tips_">根据用户注册资料，提供相关课程的试题，紧扣信息考试大纲。</div>
                    </div>
                    <div class="fun t-c">
                        <img src="../assets/fun4.png" alt="">
                        <div class="c00 f18 f-w">模拟真实环境</div>
                        <div class="c66 f14 tips_">精准还原考试环境， 全面考核，助力学生居家实战测评。</div>
                    </div>
                    <div class="fun t-c">
                        <img src="../assets/fun5.png" alt="">
                        <div class="c00 f18 f-w">智能组卷评估</div>
                        <div class="c66 f14 tips_">根据用户答题记录，智能生成试卷，灵活测评，并形成测评报告。</div>
                    </div>
                    <div class="fun t-c">
                        <img src="../assets/fun6.png" alt="">
                        <div class="c00 f18 f-w">多模式练习</div>
                        <div class="c66 f14 tips_">用户在使用过程中可根据需要，自由练习或模拟练习。</div>
                    </div>
                </div>
            </div>
        </div>
        <bottom></bottom>
    </div>
  </template>
  
  <script lang="ts" setup>
    import top from "./top.vue";
    import bottom from "./bottom.vue";
    import { ref, onMounted,onUnmounted,nextTick } from "vue";
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import 'swiper/css';
    import 'swiper/css/bundle';
    import 'swiper/css/navigation';
    import 'swiper/css/effect-fade';
    import 'swiper/css/pagination'
    const navigation = ref({
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
    });
    import { Pagination,Navigation,Autoplay } from 'swiper/modules';
    const times =ref(null);
    times.value = setInterval(() => {
        tabs();
    }, 5000);
    const tabs = () => {
        if(tab.value == 3){
            tab.value = 0
            return
        }
        tab.value=tab.value+1
    };
    const modules = [Pagination,Navigation,Autoplay]
    const hovetab = (index) => {
        clearInterval(times.value)
        times.value = null
        tab.value = index
    };
    const handtab = () => {
        if(times.value == null){
            times.value = setInterval(() => {
                tabs();
            }, 5000);
        }
    };
    const activeIndex = ref(0);
    const SlideChange = (e) => {
        activeIndex.value = e.activeIndex
    };
    const prevEl = (e,f) => {
        console.log(e,f);
    };
    const nextEl = () => {
    };
    const tab = ref<Number>(0);
    const screenWidth = ref(window.innerWidth);
        
    const handleResize = () => {
    screenWidth.value = window.innerWidth;
    };
    onMounted(() => {
        window.addEventListener('resize', handleResize);
        nextTick(() => {
            document.oncontextmenu = new Function("event.returnValue=false");
            document.onselectstart = new Function("event.returnValue=false");
            document.addEventListener("keydown", function (e) {
                if (e.key == "F12") {
                e.preventDefault(); // 如果按下键F12,阻止事件
                }
            });
        })
    });
    onUnmounted(() => {
        window.removeEventListener('resize', handleResize);
    });
  </script>
  
  <style lang="scss" scoped>
  @media screen and (max-width: 740px) {
      .swiper{
          margin-top: 104px !important;
          img{
              width: 80%;
          }
      }
                .tips_{
                    line-height: 40px;
                    font-size: 24px !important;
                }
  }
  @media screen and (max-width: 870px) {
      .diannao{
        display: none;
      }
  }
  .swiper-button{
    &:hover{

    }
  }
  .swiper-button-prev{
      &::after{
          content: '';
      }
  }
  .swiper-button-next{
      &::after{
          content: '';
      }
  }
    .diannao{
        width: 962px;
        height: 579px;
        bottom: -56px;
        left: 50%;
        margin-left: -481px;
    }
    @media (min-width: 740px) and (max-width: 1199px) {
        .swiper{
            margin-top: 88px !important;
        }
    }

    @media screen and (min-width: 1201px) {
    .function{
        height: 940px;
        background-image: url("../assets/home1.png");  
        background-size: 100% 100%;
        .funs{
            display: flex;flex-wrap: wrap;
        }
        .title{
            font-family: Adobe Heiti Std;
            font-weight: normal;
            font-size: 36px;
            color: #FFFFFF;
            line-height: 80px;
            background: linear-gradient(0deg, #0065FF 0%, #01B8E1 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            padding: 79px 0 23px 0;
        }
        // .f14{
        //     font-size: 24px !important;
        // }
        .tips{
            margin:auto;
            width:620px;
            margin-top:20px;
            line-height:30px;
            margin-bottom: 60px;
        }
        .fun{
            padding: 21px 54px 37px 45px;
            background: #FFFFFF;
            box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.02);
            border-radius: 15px;
            width: 255px;
            margin-right: 69px;
            margin-bottom: 50px;
            cursor: pointer;
            transition: transform 0.5s ease-in-out;
            .tips_{
                line-height:20px;
            }
            &:hover{
                    box-shadow: 0px 2px 30px 0px rgba(0,0,0,0.1) !important;
                    // color: #3082FF;
                    transform: scale(1.05);
                }
            &:nth-child(3n){
                margin-right: 0;
            }
            img{
                width: 91px;
                height: 91px;
            }
            .f-w{
                padding-bottom: 20px;
            }
        }
    }
    .swiper{
        margin-top: 154px;
    }
    .swiper-slide-active{
        text-align: center;
    }
  .environment{
    height: 940px;
    background-image: url("../assets/bj.png");  
    background-size: 100% 100%;
    .title{
        font-family: Adobe Heiti Std;
        font-weight: normal;
        font-size: 36px;
        color: #FFFFFF;
        line-height: 80px;
        background: linear-gradient(0deg, #0065FF 0%, #01B8E1 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        padding: 79px 0 23px 0;
    }
    .f16{
        margin:auto;
        width:620px;
        margin-top:20px;
        line-height:30px;
    }
    .left{
        width:63px;
        height:63px;
        left:0;
        cursor: pointer;
        top:50%;
        margin-top:32px;
        border-radius: 50%;
        &:hover{
            background-color: rgba(255,255,255,0.3);
        }
    }
    .right{
        border-radius: 50%;
        width:63px;
        height:63px;
        right:0;
        cursor: pointer;
        top:50%;
        margin-top:32px;
        &:hover{
            background-color: rgba(255,255,255,0.3);
        }
    }
  }
  .topic{
    width: 100%;
    background-image: url("../assets/home.png");  
    background-size: 100% 100%;
    padding-bottom: 100px;
    .title{
        font-family: Adobe Heiti Std;
        font-weight: normal;
        font-size: 36px;
        color: #FFFFFF;
        line-height: 80px;
        background: linear-gradient(0deg, #0065FF 0%, #01B8E1 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        padding: 79px 0 23px 0;
    }
    .topic_{
        margin-top: 102px;
        width: 1200px;
        // height: 520px;
        background: rgba(255,255,255,0.5);
        box-shadow: 0px 2px 30px 0px rgba(0,0,0,0.02);
        border: 1px solid #FFFFFF;
        .tab{
            div{
                width: 241px;
                height: 130px;
                background: rgba(255,255,255,0.3);
                border: 1px solid #FFFFFF;
                line-height: 130px;
            }
        }
        .tabs{
            background: linear-gradient(90deg, #067FFE, #0EC1E0) !important;
        }
        .content{
            animation: move3 1s ease-in-out forwards;
            @keyframes move3 {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }
            height: 380px;
            padding: 70px 80px;
            .contents{
                margin-top: 21px;
                line-height: 40px;
            }
            .p-a{
                width: 380px;
                height: 340px;
                right: 0;
                bottom: 18px;
            }
        }
        .con_{
            width: 500px;
            display: flex;
            margin-top: 60px;
            flex-wrap: wrap;
            div{
                width: 140px;
                height: 54px;
                margin:0 16px 16px 0;
                background: #FFFFFF;
                border-radius: 10px;
                line-height: 54px;
                cursor: pointer;
                &:hover{
                    box-shadow: 0px 2px 30px 0px rgba(48,130,255,0.3);
                    color: #3082FF;
                }
            }
        }
    }
  }
    }
    @media screen and (max-width: 1200px) {
        .function{
            // height: 940px;
            background-image: url("../assets/b.png");  
            background-size: 100% 100%;
            .title{
                font-family: Adobe Heiti Std;
                font-weight: bold;
                font-size:34px;
                color: #FFFFFF;
                margin-bottom: 56px;
                background: linear-gradient(0deg, #0065FF 0%, #01B8E1 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                padding: 60px 0 10px 0;
                position: relative;
                &::after{
                    position: absolute;
                    bottom: -20px;
                    left: 50%;
                    margin-left: -15px;
                    content: "";
                    width: 30px;
                    height: 6px;
                    background: linear-gradient(90deg, #0065FF, #01B8E1);
                    border-radius: 3px;
                }
            }
            .tips{
                margin:auto;
                margin: 20px 29px 60px 29px;
                line-height:50px;
            }
            .funs{
                display: flex;
                margin-left: 20px;
                flex-wrap: wrap;
            }
            .fun{
                padding: 20px 20px 43px 20px;
                background: #FFFFFF;
                box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.02);
                border-radius: 15px;
                width: 315px;
                margin-bottom: 20px;
                margin-right: 20px;
                img{
                    width: 91px;
                    height: 91px;
                }
                .f-w{
                    padding-bottom: 20px;
                    font-size: 26px !important;
                }
            }
        }
        .swiper{
            margin-top: 60px;
        }
        .swiper-slide-active{
            text-align: center;
        }
        // .left{
        //     display: none;
        // }
        // .right{
        //     display: none;
        // }
        .environment{
            height: 940px;
            background-image: url("../assets/pbj.png");  
            background-size: 100% 100%;
            .title{
                font-family: Adobe Heiti Std;
                font-weight: bold;
                font-size: 34px;
                color: #FFFFFF;
                margin-bottom: 56px;
                background: linear-gradient(0deg, #0065FF 0%, #01B8E1 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                padding: 79px 0 23px 0;
                position: relative;
                &::after{
                    position: absolute;
                    bottom: -10px;
                    left: 50%;
                    margin-left: -15px;
                    content: "";
                    width: 30px;
                    height: 6px;
                    background: linear-gradient(90deg, #0065FF, #01B8E1);
                    border-radius: 3px;
                }
            }
            .f26{
                font-size: 30px !important;
            }
            .f16{
                margin:auto;
                margin: 0 28px;
                margin-top:20px;
                line-height:50px;
                font-size: 24px !important;
            }
            .left{
                width:63px;
                height:63px;
                left:0;
                cursor: pointer;
                top:50%;
                margin-top:-60px;
            }
            .right{
                width:63px;
                height:63px;
                right:0;
                cursor: pointer;
                top:50%;
                margin-top:-60px;
            }
        }
        .topic{
            width: 100%;
            padding-bottom: 40px;
            background-image: url("../assets/home.png");  
            background-size: 100% 100%;
            .title{
                font-family: Adobe Heiti Std;
                font-weight: normal;
                font-size:50px;
                color: #FFFFFF;
                margin-bottom: 44px;
                background: linear-gradient(0deg, #0065FF 0%, #01B8E1 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                padding: 79px 0 23px 0;
                position: relative;
                &::after{
                    position: absolute;
                    bottom: -10px;
                    left: 50%;
                    margin-left: -15px;
                    content: "";
                    width: 30px;
                    height: 6px;
                    background: linear-gradient(90deg, #0065FF, #01B8E1);
                    border-radius: 3px;
                }
            }
            .title{
                font-size: 34px !important;
                font-weight: bold !important;
            }
            .f16{
                padding: 0 28px;
                font-size: 24px !important;
            }
        }
        .topic_{
            display: block;
            background-color: #fff ;
            border-radius: 20px;
            padding: 40px 20px;
            margin: 55px 20px 0px 20px;
            .tab{
                background-color: #EBF2FC;
                display: flex;
                border-radius: 31px;
                div{
                    height: 62px;
                    background: rgba(255,255,255,0.3);
                    line-height: 62px;
                    width: 25%;
                    border-radius: 31px;
                }
            }
            .tabs{
                background: linear-gradient(90deg, #067FFE, #0EC1E0) !important;
            }
            .content{
                animation: move3 1s ease-in-out forwards;
                @keyframes move3 {
                    0% {
                        opacity: 0;
                    }
                    100% {
                        opacity: 1;
                    }
                }
                padding: 20px 0;
                .contents{
                    margin-top: 21px;
                    line-height: 40px;
                    font-size: 24px !important;
                }
                .p-a{
                    width: 380px;
                    height: 340px;
                    right: -25px;
                    bottom: 0px;
                    transform: scale(0.8)
                }
            }
            .con_{
                width: 500px;
                display: flex;
                margin-top: 60px;
                flex-wrap: wrap;
                div{
                    font-size: 20px !important;
                    color: #494243;
                    width: 185px;
                    height: 54px;
                    margin:0 16px 16px 0;
                    background: #EBF2FC;
                    border-radius: 10px;
                    line-height: 54px;
                    cursor: pointer;
                    &:hover{
                        box-shadow: 0px 2px 30px 0px rgba(48,130,255,0.3);
                        color: #3082FF;
                    }
                }
            }
        }
    }
  </style>